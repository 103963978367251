import styled from 'styled-components'

export default styled.div`
  margin-bottom: 20px;
  background: white;
  border-radius: 5px;
  .project-site-detail-row {
    display: flex;
    width: 100%;
    height: 60px;
    .project-site-editor-image {
      position: relative;
      width: 60px;
      height: 60px;
      border-radius: 5px;
      box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
      background: ${(props) => props.theme.greyDisabled};
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }
      .project-site-editor-screenshot {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: calc(50% - 10px);
        width: 100%;
        height: 20px;
        background: rgba(0, 0, 0, 0.3);
        .project-site-editor-screenshot-btn {
          font-family: 'Prompt-Medium', sans-serif;
          font-size: 10px;
          font-weight: normal;
          font-style: normal;
          font-stretch: normal;
          line-height: 1.25;
          letter-spacing: normal;
          text-align: center;
          color: white;
          cursor: pointer;
        }
      }
    }
    .project-site-edit-name-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      width: 324px;
      height: 60px;
      padding: 12px 30px 12px 20px;
      .project-site-edit-name {
        flex: 1;
        width: 190px;
        font-family: 'Prompt', sans-serif;
        font-size: 24px;
        font-weight: bold;
        font-style: normal;
        font-stretch: normal;
        line-height: normal;
        letter-spacing: normal;
        color: rgba(0, 0, 0, 0.87);
      }
      .project-site-edit-name-btn {
        font-size: 14px;
      }
      &:after {
        content: '';
        width: 1px;
        height: calc(100% - 16px);
        position: absolute;
        top: 8px;
        right: 0px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    .project-site-edit-total-container {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 104px;
      height: 60px;
      font-family: 'Prompt', sans-serif;
      font-size: 24px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: normal;
      color: rgba(0, 0, 0, 0.87);
      &:after {
        content: '';
        width: 1px;
        height: calc(100% - 16px);
        position: absolute;
        top: 8px;
        right: 0px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    .project-site-edit-option-container {
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 490px;
      height: 60px;
      .project-site-edit-option-btn {
        font-size: 18px;
        margin-right: 30px;
      }
      &:after {
        content: '';
        width: 1px;
        height: calc(100% - 16px);
        position: absolute;
        top: 8px;
        right: 0px;
        background: rgba(0, 0, 0, 0.2);
      }
    }
    .project-site-edit-delete-container {
      width: 108px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      .project-site-edit-delete-btn {
        font-size: 18px;
      }
    }
  }
  .project-site-card-content-container {
    display: none;
    &.expand {
      display: block;
    }
    .create-area-row-container {
      display: flex;
      width: 100%;
      height: 45px;
      background: #eaeaea;
      .create-area-btn {
        font-size: 20px;
      }
      .create-area-row-input-container {
        display: flex;
        width: 592px;
        height: 100%;
        padding: 10px 0px 10px 20px;
        .create-area-input {
          width: 190px;
          font-family: 'Prompt-Bold', sans-serif;
          font-size: 20px;
          font-weight: bold;
          font-style: normal;
          font-stretch: normal;
          line-height: normal;
          letter-spacing: normal;
          color: rgba(31, 45, 57, 0.7);
          background: transparent;
          border: none;
          outline: none;
          border-bottom: 1px solid rgba(31, 45, 57, 0.7);
        }
      }
      .create-area-row-option-container {
        width: 490px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
      .create-area-row-cancel-container {
        width: 108px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`
