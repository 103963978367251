import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import TextButton from '../TextButton/Loadable'
import AreaRowCard from '../AreaRowCard/Loadable'
import ModalOverlay from '../ModalOverlay/Loadable'
import ModalNotification from '../ModalNotification/Loadable'
import TextInput from '../TextInput/Loadable'
import Button from '../Button/Loadable'

import ProjectSiteEditorCardStyled from './styledComponent'

import defaultImg from '../../asset/images/default.png'

import MESSAGE from './message'

class ProjectSiteEditorCard extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      isExpandCard: true,
      inputSiteName: '',
      isSiteNameValid: undefined,
      showUpdateSiteNameModal: false,
      showDeleteSiteModal: false,
      newAreaNameInput: '',
      isAreaNameValid: undefined,
      showCreateAreaModal: false,
      siteScreenshotURL: '',
      isSiteScreenshotURLValid: undefined,
      showEditSiteScreenshotModal: false
    }
  }

  getMessage(property) {
    const msg = MESSAGE[this.props.currentUser?.language]
    return msg[property]
  }

  setShowEditSiteScreenshotModal = () => {
    this.setState({
      showEditSiteScreenshotModal: true,
      isSiteScreenshotURLValid: undefined,
      siteScreenshotURL: this.props.screenshot || ''
    })
  }

  handleCloseEditSiteScreenshotModal = () => {
    this.setState({
      showEditSiteScreenshotModal: false,
      siteScreenshotURL: '',
      isSiteScreenshotURLValid: undefined
    })
  }

  getEditSiteScreenshot() {
    const image = this.props.screenshot || defaultImg
    return (
      <div className="project-site-editor-image">
        <img src={image} alt="" />
        <div className="project-site-editor-screenshot">
          <div className="project-site-editor-screenshot-btn" onClick={this.setShowEditSiteScreenshotModal}>
            {this.getMessage('edit_btn_txt')}
          </div>
        </div>
      </div>
    )
  }

  handleEditSiteNameBtnClicked = () => {
    this.setState({
      showUpdateSiteNameModal: true,
      inputSiteName: this.props.name,
      isSiteNameValid: undefined
    })
  }

  getSiteNameSection() {
    return (
      <div className="project-site-edit-name-container">
        <div className="project-site-edit-name prevent-text-overflow">{this.props.name}</div>
        <TextButton
          className="project-site-edit-name-btn"
          label={this.getMessage('edit_btn_txt')}
          onClick={this.handleEditSiteNameBtnClicked}
          primary
        />
      </div>
    )
  }

  getSiteTotalAreaSection() {
    return <div className="project-site-edit-total-container">{this.props.listArea.length}</div>
  }

  getSiteTotalCameraSection() {
    const totalCameras = this.props.listArea.reduce((total, current) => {
      return total + current.cameras.length
    }, 0)
    return <div className="project-site-edit-total-container">{totalCameras}</div>
  }

  handleAddAreaBtnClicked = () => {
    this.setState({
      isExpandCard: true,
      showCreateAreaModal: true,
      isAreaNameValid: undefined,
      newAreaNameInput: ''
    })
  }

  handleExpandListBtnClicked = () => {
    this.setState({ isExpandCard: true })
  }

  handleCollapseListBtnClicked = () => {
    this.setState({ isExpandCard: false })
  }

  getSiteCardOptionSection() {
    const expandListBtn = (
      <TextButton
        className="project-site-edit-option-btn"
        label={this.getMessage('expand_list_btn_txt')}
        onClick={this.handleExpandListBtnClicked}
        primary
      />
    )
    const collapseListBtn = (
      <TextButton
        className="project-site-edit-option-btn"
        label={this.getMessage('collapse_list_btn_txt')}
        onClick={this.handleCollapseListBtnClicked}
        primary
      />
    )
    return (
      <div className="project-site-edit-option-container">
        <TextButton
          className="project-site-edit-option-btn"
          label={this.getMessage('add_area_btn_txt')}
          onClick={this.handleAddAreaBtnClicked}
          primary
        />
        {this.state.isExpandCard ? collapseListBtn : expandListBtn}
      </div>
    )
  }

  getSiteCardDeleteSection() {
    return (
      <div className="project-site-edit-delete-container">
        <TextButton className="project-site-edit-delete-btn" label={this.getMessage('delete_btn_txt')} onClick={this.setShowDeleteSiteModal} danger />
      </div>
    )
  }

  getProjectSiteDetailRow() {
    return (
      <div className="project-site-detail-row">
        {this.getEditSiteScreenshot()}
        {this.getSiteNameSection()}
        {this.getSiteTotalAreaSection()}
        {this.getSiteTotalCameraSection()}
        {this.getSiteCardOptionSection()}
        {this.getSiteCardDeleteSection()}
      </div>
    )
  }

  handleCancelCreateArea = () => {
    this.setState({ isCreatingArea: false })
  }

  handleAddCameraBetweenArea = (draggingData, dropTargetResultData) => {
    this.props.onAddCameraBetweenArea(draggingData.id, draggingData.areaID, dropTargetResultData.areaID, this.props.siteID)
  }

  getListAreaDetail() {
    const listAreaCard = this.props.listArea.map((areaData) => {
      return (
        <AreaRowCard
          key={areaData.area_id}
          name={areaData.area_name}
          siteID={this.props.siteID}
          areaID={areaData.area_id}
          listCamera={areaData.cameras}
          currentUser={this.props.currentUser}
          onUpdateAreaName={this.props.onUpdateAreaName}
          onAddCameraBetweenArea={this.handleAddCameraBetweenArea}
          onCreateNewCamera={this.props.onCreateNewCamera}
          onDeleteArea={this.props.onDeleteArea}
          onDeleteCamera={this.props.onDeleteCamera}
          onUpdateCameraData={this.props.onUpdateCameraData}
          onCreateSpeaker={this.props.onCreateSpeaker}
          onUpdateSpeaker={this.props.onUpdateSpeaker}
          onDeleteSpeaker={this.props.onDeleteSpeaker}
          isRecalculatingZone={this.props.isRecalculatingZone}
        />
      )
    })
    return <div className="project-list-area-container">{listAreaCard}</div>
  }

  getContent() {
    let className = 'project-site-card-content-container'
    if (this.state.isExpandCard) {
      className += ' expand'
    }
    return <div className={className}>{this.getListAreaDetail()}</div>
  }

  _isSiteNameValid = () => {
    const name = this.state.inputSiteName.trim()
    const isSiteNameValid = name.length > 0
    this.setState({ isSiteNameValid })
    return isSiteNameValid
  }

  handleUpdateSiteNameClicked = () => {
    if (this._isSiteNameValid()) {
      const siteName = this.state.inputSiteName
      this.props.onUpdateSiteName(siteName, this.props.siteID)
      this.handleCloseUpdateSiteNameModal()
    }
  }

  handleSiteNameChanged = (e, newValue) => {
    this.setState({
      inputSiteName: newValue
    })
  }

  handleCloseUpdateSiteNameModal = () => {
    this.setState({
      showUpdateSiteNameModal: false,
      isSiteNameValid: undefined,
      inputSiteName: ''
    })
  }

  getModalUpdateSiteName() {
    let output = null
    if (this.state.showUpdateSiteNameModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseUpdateSiteNameModal}>
          <div className="modal-content-container" onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('input_site_name_label')}
                  id="edit-project-name-input"
                  placeHolder={this.getMessage('input_site_name_placeholder')}
                  value={this.state.inputSiteName}
                  onChange={this.handleSiteNameChanged}
                  errorText={this.state.isSiteNameValid === false ? this.getMessage('dialog_update_site_error_txt') : ''}
                />
              </div>
            </div>
            <Button
              className="full-width"
              id="update-project-save-btn"
              onClick={this.handleUpdateSiteNameClicked}
              text={this.getMessage('save_btn_txt')}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  setShowDeleteSiteModal = () => {
    this.setState({
      showDeleteSiteModal: true
    })
  }

  handleCloseDeleteSiteModal = () => {
    this.setState({
      showDeleteSiteModal: false
    })
  }

  handleDeleteSiteBtnClicked = () => {
    this.handleCloseDeleteSiteModal()
    this.props.onDeleteSite(this.props.siteID)
  }

  getDeleteSiteModal() {
    let output = null
    if (this.state.showDeleteSiteModal) {
      output = (
        <ModalNotification
          title={this.getMessage('delete_site_dialog_title')}
          message={this.getMessage('delete_site_dialog_message')}
          onSecondaryButtonClick={this.handleCloseDeleteSiteModal}
          onPrimaryButtonClick={this.handleDeleteSiteBtnClicked}
          onOverLayClose={this.handleCloseDeleteSiteModal}
          secondaryButtonText={this.getMessage('delete_site_dialog_cancel_btn_txt')}
          primaryButtonText={this.getMessage('delete_site_dialog_delete_btn_txt')}
        />
      )
    }
    return output
  }

  handleCloseCreateNewAreaModal = () => {
    this.setState({
      showCreateAreaModal: false,
      newAreaNameInput: '',
      isAreaNameValid: undefined
    })
  }

  handleNewAreaNameInputChanged = (e, newValue) => {
    this.setState({
      newAreaNameInput: newValue
    })
  }

  _isAreaNameValid = () => {
    const name = this.state.newAreaNameInput.trim()
    const isAreaNameValid = name.length > 0
    this.setState({ isAreaNameValid })
    return isAreaNameValid
  }

  handleCreateNewAreaBtnClicked = () => {
    if (this._isAreaNameValid()) {
      const newAreaName = this.state.newAreaNameInput
      this.props.onCreateNewArea(newAreaName, this.props.siteID)
      this.handleCloseCreateNewAreaModal()
    }
  }

  getCreateAreaModal() {
    let output = null
    if (this.state.showCreateAreaModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseCreateNewAreaModal}>
          <div className="modal-content-container" onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('create_area_dialog_label_txt')}
                  id="edit-project-name-input"
                  placeHolder={this.getMessage('create_area_dialog_placeholder_txt')}
                  value={this.state.newAreaNameInput}
                  onChange={this.handleNewAreaNameInputChanged}
                  errorText={this.state.isAreaNameValid === false ? this.getMessage('dialog_create_area_error_txt') : ''}
                />
              </div>
            </div>
            <Button
              className="full-width"
              id="update-project-save-btn"
              onClick={this.handleCreateNewAreaBtnClicked}
              text={this.getMessage('create_area_dialog_create_btn_txt')}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  handleSiteScreenshotChanged = (e, newValue) => {
    this.setState({
      siteScreenshotURL: newValue
    })
  }

  _isSiteScreenshotURLValid = () => {
    const name = this.state.siteScreenshotURL.trim()
    const isSiteScreenshotURLValid = name.length > 0
    this.setState({ isSiteScreenshotURLValid })
    return isSiteScreenshotURLValid
  }

  handleUpdateSiteScreenshotBtnClicked = () => {
    if (this._isSiteScreenshotURLValid()) {
      const urlScreenshot = this.state.siteScreenshotURL
      this.props.onUpdateSiteScreenshot(urlScreenshot, this.props.siteID)
      this.handleCloseEditSiteScreenshotModal()
    }
  }

  getEditSiteScreenshotModal() {
    let output = null
    if (this.state.showEditSiteScreenshotModal) {
      output = (
        <ModalOverlay onClose={this.handleCloseEditSiteScreenshotModal}>
          <div className="modal-content-container" onClick={(e) => e.stopPropagation()}>
            <div>
              <div className="modal-content-row-container">
                <TextInput
                  mode="material-design"
                  type="text"
                  label={this.getMessage('update_site_screenshot_dialog_label_txt')}
                  id="edit-project-name-input"
                  placeHolder={this.getMessage('update_site_screenshot_dialog_placeholder_txt')}
                  value={this.state.siteScreenshotURL}
                  onChange={this.handleSiteScreenshotChanged}
                  errorText={this.state.isSiteScreenshotURLValid === false ? this.getMessage('dialog_site_url_error_txt') : ''}
                />
              </div>
            </div>
            <Button
              className="full-width"
              id="update-project-save-btn"
              onClick={this.handleUpdateSiteScreenshotBtnClicked}
              text={this.getMessage('save_btn_txt')}
            />
          </div>
        </ModalOverlay>
      )
    }
    return output
  }

  render() {
    return (
      <ProjectSiteEditorCardStyled>
        {this.getProjectSiteDetailRow()}
        {this.getContent()}
        {this.getModalUpdateSiteName()}
        {this.getCreateAreaModal()}
        {this.getDeleteSiteModal()}
        {this.getEditSiteScreenshotModal()}
      </ProjectSiteEditorCardStyled>
    )
  }
}

ProjectSiteEditorCard.propTypes = {
  siteID: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  screenshot: PropTypes.string,
  listArea: PropTypes.arrayOf(
    PropTypes.shape({
      area_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      area_name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      cameras: PropTypes.array.isRequired
    })
  ).isRequired,
  currentUser: PropTypes.shape({
    admin: PropTypes.bool,
    email: PropTypes.string,
    firstname: PropTypes.string,
    language: PropTypes.string,
    lastname: PropTypes.string,
    manager: PropTypes.bool,
    token: PropTypes.string
  }),
  onDeleteSite: PropTypes.func.isRequired,
  onUpdateSiteName: PropTypes.func.isRequired,
  onUpdateSiteScreenshot: PropTypes.func.isRequired,
  onUpdateAreaName: PropTypes.func.isRequired,
  onAddCameraBetweenArea: PropTypes.func.isRequired,
  onCreateNewCamera: PropTypes.func.isRequired,
  onCreateNewArea: PropTypes.func.isRequired,
  onDeleteArea: PropTypes.func.isRequired,
  onDeleteCamera: PropTypes.func.isRequired,
  onUpdateCameraData: PropTypes.func.isRequired,
  onCreateSpeaker: PropTypes.func.isRequired,
  onUpdateSpeaker: PropTypes.func.isRequired,
  onDeleteSpeaker: PropTypes.func.isRequired,
  isRecalculatingZone: PropTypes.bool
}

export default ProjectSiteEditorCard
