const MESSAGE = {
  EN: {
    edit_btn_txt: 'Edit',
    save_btn_txt: 'Save',
    cancel_btn_txt: 'Cancel',
    add_area_btn_txt: 'Add Area',
    add_camera_btn_txt: 'Add Camera',
    expand_list_btn_txt: 'Expand List',
    collapse_list_btn_txt: 'Collapse List',
    delete_btn_txt: 'Delete',
    input_site_name_label: 'SITE NAME',
    input_site_name_placeholder: 'Site name',
    delete_site_dialog_title: 'Delete Site',
    delete_site_dialog_message: 'Are you sure to delete site ?',
    delete_site_dialog_delete_btn_txt: 'Delete',
    delete_site_dialog_cancel_btn_txt: 'Cancel',
    create_area_dialog_label_txt: 'AREA NAME',
    create_area_dialog_placeholder_txt: 'Area name',
    update_site_screenshot_dialog_label_txt: 'SITE IMAGE',
    update_site_screenshot_dialog_placeholder_txt: 'Image URL',
    create_area_dialog_create_btn_txt: 'Create',
    dialog_update_site_error_txt: 'Invalid name.',
    dialog_create_area_error_txt: 'Invalid name.',
    dialog_site_url_error_txt: 'Invalid URL'
  },
  TH: {
    edit_btn_txt: 'แก้ไข',
    save_btn_txt: 'บันทึก',
    cancel_btn_txt: 'ยกเลิก',
    add_area_btn_txt: 'เพิ่มพื้นที่',
    add_camera_btn_txt: 'เพิ่มกล้อง',
    expand_list_btn_txt: 'ขยายลิสต์',
    collapse_list_btn_txt: 'ซ่อนลิสต์',
    delete_btn_txt: 'ลบ',
    input_site_name_label: 'ชื่อไซต์',
    input_site_name_placeholder: 'ไซต์',
    delete_site_dialog_title: 'ลบไซต์',
    delete_site_dialog_message: 'คุณต้องการที่จะลบไซต์หรือไม่ ?',
    delete_site_dialog_delete_btn_txt: 'ลบ',
    delete_site_dialog_cancel_btn_txt: 'ยกเลิก',
    create_area_dialog_label_txt: 'ชื่อพื้นที่',
    create_area_dialog_placeholder_txt: 'ชื่อ',
    update_site_screenshot_dialog_label_txt: 'SITE IMAGE',
    update_site_screenshot_dialog_placeholder_txt: 'Image URL',
    create_area_dialog_create_btn_txt: 'สร้าง',
    dialog_update_site_error_txt: 'ชื่อไม่ถูกต้อง',
    dialog_create_area_error_txt: 'ชื่อไม่ถูกต้อง',
    dialog_site_url_error_txt: 'URL ไม่ถูกต้อง'
  }
}

export default MESSAGE
